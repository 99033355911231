<template>
  <div id="SupportCenter">
    <div class="header">
      <span>SUPPORT</span>
      <h2>客服中心</h2>
    </div>
    <div class="tab">
      <a class="active">常見問題</a>
      <router-link to="/contact">聯絡我們</router-link>
    </div>
    <div class="question_section">
      <div
        class="card"
        :class="{ active: item_index == active_question }"
        v-for="(item, item_index) in questions"
        :key="item.question_id"
      >
        <a @click="OpenQuestion(item_index)">
          <p>
            <b>{{ item_index + 1 }}</b>
            <span class="title">{{ item.title }}</span>
          </p>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="plus"
            class="svg-inline--fa fa-plus fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
            ></path>
          </svg>
        </a>
        <div class="content" v-html="item.description"></div>
      </div>
      <div class="card">
        <router-link to="/ship_doc">
          <p>
            <b>{{ questions.length + 1 }}</b>
            <span class="title">退/換貨政策與服務條款細則</span>
          </p>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-10"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
            ></path>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'support',
  components: {},
  data() {
    return {
      active_question: -1,
      questions: [],
    };
  },
  methods: {
    OpenQuestion(val) {
      if (val == this.active_question) {
        this.active_question = -1;
      } else {
        this.active_question = val;
      }
    },
    async GetQuestionData() {
      let result = await this.SendGetData(
        process.env.VUE_APP_BASE_API + 'questions/get_questions_list.php'
      );
      if (result != 'error') {
        let data = JSON.parse(result.data);
        this.questions = data;
        this.questions.sort((a, b) => {
          return a.position - b.position;
        });
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
  },
  created() {
    this.GetQuestionData();
  },

  metaInfo() {
    return {
      title: '客服中心｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content: '有任何訂單相關的問題歡迎來訊來電與我們聯絡',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content: '有任何訂單相關的問題歡迎來訊來電與我們聯絡',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content: '有任何訂單相關的問題歡迎來訊來電與我們聯絡',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '客服中心｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '客服中心｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '客服中心｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
